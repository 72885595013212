import {Settings} from "../config.js";

export const I18n = {
  Eng: {
    header: {
      home: "Home",
      registries: "Registries",
      indicators: "Indicators",
      dashboard: "Dashboard",
      searchAnalyse: "Search & Analyse",
      templates: "Templates",
      administration: "Administration",
      events: "Events",
      participants: "Participants",
      trainers:  Settings.Tenant.IsUlead ? "Trainers" : "Speakers",
      facilitators: Settings.Tenant.IsUlead ? "Facilitators" : "Experts of the Expert Platform" ,
      userManagement: "User Management",
      mailLog: "Mail Log",
      team: "Team",
      fundingSource: "Service Request",
      employerLevel: "Employer Level",
      employerType: "Employer Type",
      oblast: "Oblast",
      category: "Category",
      targetGroup: "Target Group",
      targetLevel: "Levels of Target Group",
      organizer: "Organizer",
      eventFormat: "Event Format",
      eventCharacter: "Event Character",
      communities: "Municipalities",
      regionalInitiatives: "Regional Initiatives",
      practice: "Good Practices",
      support: "Support",
      supportFeedback: "Feedback",
      contactForm: "Contact Form",
      forum: "Forum",
      thematicSupportPackages: "Thematic Support Packages",
      programmeIndicators: "Programme Indicators",
      goodPractice: "Good Practice",
      csoGrants: "CSO Grants",
      donorProjects: "Donor Funded Projects",
      graphs: "Graphs",
      map: "Interactive Mapping Tool"
    },
    home: {
      registries: "REGISTRIES",
      events: "Events",
      eventsText: Settings.Tenant.IsUlead ? "See and create new U-LEAD events" : "See and create new 3*E4U events",
      participants: "Participants",
      participantsText: Settings.Tenant.IsUlead ? "See and create participants of U-LEAD events" : "See and create participants of 3*E4U events",
      trainers:  Settings.Tenant.IsUlead ? "Trainers" : "Speakers",
      trainersText: "Browse & add trainers and experts",
      facilitators:  Settings.Tenant.IsUlead ? "Facilitators" : "Experts of the Expert Platform" ,
      facilitatorsText:  Settings.Tenant.IsUlead ? "Browse & add facilitators" : "Browse & add Experts",
      hromadas: "Municipalities",
      hromadasText: "See the latest hromada information",
      practice: "Good Practices",
      practiceText: "Browse, add & edit good practices",
      indicators: "indicators",
      program: "Programme Indicators",
      programText: Settings.Tenant.IsUlead ? "View or measure the current status of U-LEAD indicators":  "View or measure the current status of 3*E4U indicators",
      dashboard: "Dashboard",
      maps: "Maps & Graphs",
      mapsText:Settings.Tenant.IsUlead ?  "View and download visualisations of U-LEAD data" : "View and download visualisations of 3*E4U data",
      graphs: "Graphs",
      graphsText: "Subtitle",
      search: "SEARCH & ANALYSE",
      oes: "Events and participant data",
      oesText: "Search and analyse events and participant data",
      xs: "Executive Search (XS)",
      xsText: Settings.Tenant.IsUlead ? "Analyze participants across U-LEAD events" : "View and download visualisations of 3*E4U data",
      specialInitiatives: "Special Initiatives",
    },
    events: {
      events: "EVENTS",
      header: "EVENTS",
      event: "Event",
      create: "Create an Event",
      // search: "Search for Event",
      id: "ID",
      name: "Name",
      startDate: "Start Date",
      endDate: "End Date",
      oblast: "Oblast",
      edit: "Edit Event ",
      nameEn: "Name English",
      eventAddress: "Event Address",
      placeOfEventCoordinates: "Place of Event Coordinates",
      nameUa: "Name Ukrainian",
      region: "Region",
      eventFormat: "Event Format",
      organizers: "Organizers",
      mediaReferences: "Media References",
      internalFeedback: "Internal Feedback/MinRegion Priorities",
      descEn: "Description English",
      targetGroups: "Target Groups",
      targetLevels: "Levels of Target Group",
      trainers:  Settings.Tenant.IsUlead ? "Trainers" : "Speakers",
      categories: "Categories",
      categories_p2: "Event Theme",
      results: "Results",
      descUa: "Description Ukrainian",
      fundingSources: "Funding Sources/Service Request",
      eventReport: Settings.Tenant.IsUlead ? "Event Report" : "Event Agenda / Report",
      eventFoto: "Event Photo",
      search: "Search",
      addParticipant: "Add Participant",
      downloadParticipantsTemplate: "Download template",
      importParticipants: "Import participants",
      printList: "Print List",
      printEmptyList: "Print Empty List",
      printEventInfo: "Print Event Info",
      expectedParticipants: "Expected Number of Participants",
      actualParticipants: "Total number of participants",
      createdBy: Settings.Tenant.IsUlead ?  "Created by" : "Position of responsible person",
      owner: Settings.Tenant.IsUlead ? "Owner" : "Position of responsible person",

      goToSurvey: "Go To Survey",
      getEvaluationForm: "Get Evaluation Form",
      viewStatistics: "View Statistics",
      createSurvey: "Create Survey",
      employerTypeDistribution: "Employer type distribution",
      location: "Location",
      participants: "Participants",
      cellPhone: "Cell Phone",
      gender: "Gender",
      employerLevel: "Employer Level",
      employerType: "Employer Type",

      uniqueHromadas: "UNIQUE MUNICIPALITIES",
      employerLevelDistribution: "Employer level distribution",
      genderDistribution: "Gender distribution",
      participantRegistered: "PARTICIPANTS REGISTERED",
      eventCharacters: "Important event",

      thematicPackagesAndNetworkTitle:
        "Is this event implemented through TSPs or exchange network?",
      thematicPackagesTitle:
          "Is this event implemented through U-LEAD's TSP comprehensive programmes?",

      europeActoinsTitle:
        "Is this event implemented through any of Regional initiatives?",

      trainingPackageTypes: "Training Packages",
      thematicModules: "Thematic Modules",

      clusterA: "Cluster A: Becoming an effective and capable AH",
      clusterB:
        "Cluster B: Designing and delivering high quality municipal services",
      clusterC: "Cluster C: Facilitating local and regional development",
      facilitators: "Facilitators",

      actualNumberOfParticipants: "Total number of participants",
      deleteParticipant: "Remove from event",
      regionalInitiatives: "Regional initiatives",
      thematicSupportPackage: "Thematic Support Package",
      responsiblePerson: Settings.Tenant.IsUlead ?  "Responsible Person" : "Name of responsible Person",
      reportMissing: "Report missing",
      turnOnPreregistration: "Preregistration turned off",
      turnOffPreregistration: "Preregistration turned on",
      copyToClipboard: "Copy link",
      printPreregList: "Download Preregistered Participants",
      preregisteredParticipants: "Preregistered Participants",
      kind: "Event form",
      hybrid: "Hybrid",
      online: "Online",
      live: "Live",
      alternative: "Alternative (phone, e-mail etc.)",
      experts: "Experts",
      numberWomenParticipated: "Number of female participants",
      crossCuttingTopics: "Cross-cutting topics",
    },
    participants: {
      header: "Participants",
      participants: "Participants",
      create: "Create a new Participant",
      search: "Search for participant",
      edit: "Edit Participant",
      nameTranscription: "Name Transliteration",
      participant: "Participant",
      name: "Name",
      gender: "Gender",
      email: "Email",
      cellPhone: "Cell Phone",
      personalDetails: "Personal Details",
      events: "Events",
      employer: "Employer",
      employment: "Employment",
      position: "Position",
      employerName: "Name of Employer",
      employerNameShort : "Employer",
      employerLocation: "Location of Employer",
      employerCoordinates: "Employer Coordinates",
      employerAddress: "Employer Address (town and region)",
      employerType: "Employer Type",
      employerTypeShort: "Type",
      employerLevel: "Level",
      community: "Municipality",
      region: "Oblast",
      placeOfEventCoordinates: "Employer Coordinates",
      contactAddress: "Address",
      matchFoundTitle: "Participant Existed",
      noMatchFoundTitle: "No Match Found",
      noMatchFoundContent:
        "There is no existing participant in system with such parameters",
      checked: "Check for existing participant",
      oblast: "Oblast",
      eventsCount:"Events",
    },
    trainers: {
      header:  Settings.Tenant.IsUlead ? "Trainers" : "Speakers",
      trainers:  Settings.Tenant.IsUlead ? "Trainers" : "Speakers",
      trainer: "Speaker",
      create: "Create a Speaker",
      search: "Search for speaker",
      edit:  Settings.Tenant.IsUlead ? "Edit rainers" : "Edit Speakers",
      id: "ID",
      name: "Name",
      nameTranscription: "Name Transliteration",
      gender: "Gender",
      email: "Email",
      cellPhone: "Cell Phone",
      info: "Info",
      personalDetails: "Personal details",
      surveyRating: "Rating",
    },
    experts: {
      header: "Experts",
      trainers: "Experts",
      trainer: "Experts",
      create: "Create an Expert",
      search: "Search for experts",
      edit: "Edit Expert",
      id: "ID",
      name: "Name",
      surname: "Surname",
      information: "Information",
      gender: "Gender",
      email: "Email",
      cellPhone: "Cell Phone",
      personalDetails: "Personal details",
      expertise: "Area of expertise",
      country: "Country",
      position: "Position"
    },
    special_initiatives: {
      header: "Special Initiatives",
      create: "Create a Special Initiative",
      search: "Search for Special Initiatives",
      edit: "Edit Special Initiative",
      id: "ID",
      nameEng: "Name (English)",
      nameUa: "Name (Ukrainian)",
      descriptionEng: "Description (English)",
      descriptionUa: "Description (Ukrainian)",
      startDate: "Start Date",
      endDate: "End Date",
      partners: "Partners",
      partnersName: "Partners Name",
      owner: "Owner",
      attachments: "Attachments",
      addPhoto: "Add Photo",
      addReport: "Add Report",
      format: "Format",
    },
    facilitators: {
      header: Settings.Tenant.IsUlead ? "Facilitators" : "Experts of the Expert Platform" ,
      facilitators: Settings.Tenant.IsUlead ? "Facilitators" : "Experts of the Expert Platform" ,
      facilitator: Settings.Tenant.IsUlead ? "Facilitator" : "Experts" ,
      create: Settings.Tenant.IsUlead ? "Create a Facilitator" : "Create an Expert",
      search: Settings.Tenant.IsUlead ? "Search for facilitator" : "Search for experts",
      edit:  Settings.Tenant.IsUlead ? "Edit Facilitator" : "Edit Expert",
      id: "ID",
      name: "Name",
      nameTranscription: "Name Transliteration",
      gender: "Gender",
      email: "Email",
      cellPhone: "Cell Phone",
      info: "Info",
      surveyRating: "Rating",
    },
    templates: {
      templates: "Templates and Documents",
      createNew: "Create a new template",
      edit: "Редагувати шаблон",
      create: "Create template",
      title: "Title",
      subTitle: "Sub Title",
      file: "File",
    },
    categories: {
      categories: "Categories",
      header: "Categories",
      category: "Category",
      create: "Create New Category",
      search: "Search for category",
      result: "Result",
      edit: "EDIT CATEGORY",
      langKey: "Language Key",
      ua: "UA",
      en: "EN",
      active: "Active",
      descEn: "Desc En",
      descUa: "Desc Ua",
      keywords: "Keywords",
    },
    categories_p2: {
      header: "Event Theme",
      categories: "Event Theme",
      category: "EVENT THEME",
      create: "Create New Event Theme",
      search: "Search for Event theme",
      result: "Result",
      edit: "EDIT EVENT THEME",
      langKey: "Language Key",
      ua: "UA",
      en: "EN",
      active: "Active",
      descEn: "Desc En",
      descUa: "Desc Ua",
      keywords: "Keywords",
    },
    communities: {
      header: "Municipalities",
      communities: "Municipalities",
      community: "Hromada",
      search: "Search for Hromada",
      generalInformation: "General Information",
      ahCentre: "Centre",
      centreEn: "Centre EN",
      centreUa: "Centre UA",
      nameUa: "Name Ukrainian",
      nameEn: "Name English",
      id: "ID",
      title: "Name",
      center: "Center",
      councilSize: "# of Settlements",
      created: "Date of 1st election",
      establishment: "Date of establishment",
      koatuu: "Code",
      admin3pCode: "Admin3PCode",
      region: "Oblast",
      showOnMap: "Show on map",
      initiatives: "Initiatives",
      support: "TSP Support",
      participants: "Participants",
      population: "Population",
      square: "Size (km2)",
      updatedAt: "Updated At",
      date: "Date",
      eventTitle: "Event Title",
      eventFormat: "Event Format",
      regionalInitiative: "Regional Initiatives",
      participantName: "Name",
      gender: "Gender",
      cellPhone: "Cell Phone",
      position: "Position",
      employerType: "Employer Type",
      supportHromada: "Support To Hromada",
      uleadIndicators: "U-LEAD INDICATORS",
      so1dInfo: "Number of internal procedures adjusted",
      r2aInfo: "Number of fees/taxes introduced",
      uleadStats: "U-LEAD STATISTICS",
      totalParticipantsCount: "Total Number of Participants",
      tspEventsCount: "TSP Events",
      regionalEventsCount: "Regional Initiative Events",
      totalEventsNumber: "Total Number of Events",
      genderBalance: "Gender Balance",
      formatEventsCount: "Format of Events",
      employerTypeCount: "Employer Type",
      employerName: "Employer Name",
      otherDonorSupport: "Other Donor Support",
      numberOfEvents: "Number of events",
    },
    employerLevel: {
      header: "EMPLOYER LEVELS",
      employerLevels: "EMPLOYER LEVELS",
      employerLevel: "EMPLOYER LEVEL",
      create: "Create a new Employer Level",
      search: "Search for Employer Level",
      edit: "EDIT EMPLOYER LEVEL",
    },
    employerType: {
      header: "EMPLOYER TYPES",
      employerTypes: "EMPLOYER TYPES",
      employerType: "EMPLOYER TYPE",
      create: "Create a new Employer Type",
      search: "Search for Employer Type",
      edit: "EDIT EMPLOYER TYPE",
    },
    europeActions: {
      europeActions: "Regional initiatives",
      europeAction: "Action",
      create: "Create a new Action",
      search: "Search for Action",
      edit: "EDIT ACTION",
    },
    eventCharacter: {
      header: "EVENT CHARACTERS",
      eventCharacters: "EVENT CHARACTERS",
      eventCharacter: "EVENT CHARACTER",
      create: "Create a new Event Character",
      search: "Search for Event Character",
      edit: "EDIT EVENT CHARACTER",
    },
    format: {
      header: "FORMATS",
      formats: "FORMATS",
      format: "FORMAT",
      create: "Create a new Format",
      search: "Search for Format",
      edit: "EDIT FORMAT",
    },
    fundingSources: {
      header: "FUNDING SOURCES/SERVICE REQUESTS",
      fundingSources: "FUNDING SOURCES/SERVICE REQUESTS",
      fundingSource: "FUNDING SOURCE/SERVICE REQUEST",
      create: "Create a new Funding Sources/Service Request",
      search: "Search for Funding Sources/Service Request",
      edit: "EDIT FUNDING SOURCE/SERVICE REQUEST",
    },
    organizer: {
      organizers: "ORGANIZERS",
      organizer: "ORGANIZER",
      create: "Create a new Organizer",
      search: "Search for Organizer",
      edit: "EDIT ORGANIZER",
    },
    region: {
      regions: "Regions",
      region: "Region",
      create: "Create a new Region",
      search: "Search for Region",
      edit: "EDIT Region",
    },
    targetGroup: {
      header: "TARGET GROUPS",
      targetGroups: "TARGET GROUPS",
      targetGroup: "TARGET GROUP",
      create: "Create a new Target Group",
      search: "Search for Target Group",
      edit: "EDIT TARGET GROUP",
    },
    targetLevel: {
      header: "TARGET LEVELS",
      targetLevels: "TARGET LEVELS",
      targetLevel: "TARGET LEVEL",
      create: "Create a new Target Level",
      search: "Search for Target Level",
      edit: "EDIT TARGET LEVEL",
    },
    team: {
      teams: "TEAMS",
      team: "TEAM",
      create: "Create a new Team",
      search: "Search for Team",
      edit: "EDIT TEAM",
    },
    thematicPackages: {
      thematicPackages: "THEMATIC SUPPORT PACKAGES",
      thematicPackage: "THEMATIC SUPPORT PACKAGE",
      create: "Create a new package",
      search: "Search for package",
      edit: "EDIT PACKAGE",
    },
    csoGrants: {
      csoGrants: "CSO Grants",
      csoGrant: "CSO Grant",
      create: "Create a new CSO Grant",
      search: "Search for CSO Grant",
      edit: "EDIT CSO GRANT",
    },
    csoGrant: {
      search: "Search",
      id: "ID",
      year: "Year",
      projectTitle: "Project Title",
      projectTitleEn: "",
      cso: "CSO",
      csoEn: "",
    },
    donorProjects: {
      donorProjects: "Donor Funded Projects",
      donorProject: "Donor Funded Project",
      create: "Create a new Donor Funded Project",
      search: "Search for Donor Funded Project",
      edit: "EDIT DONOR FUNDED PROJECT",
    },
    donorProject: {
      search: "Search",
      id: "ID",
      title: "Project Title",
      donor: "Donor",
    },
    user: {
      users: "USERS",
      user: "USER",
      create: "Create a new user",
      search: "Search for user",
      edit: "EDIT USER",
      id: "ID",
      login: "Login",
      email: "Email",
      activated: "Activated",
      lang_key: "Language",
      authorities: "Profiles",
      created_date: "Created Date",
      last_modified_by: "Modified by",
      last_modified_date: "Modified Date",
      name: "Name",
      password: "Password",
      passwordConfirm: "Confirm Password",
      createdBy: "Created by",
      profiles: "Profiles",
    },
    indicators: {
      create: "Create an Indicator",
      code: "Code",
      indicatorText: "Indicator Text",
      frequency: "Frequency",
      updated: "Updated",
      currentValue: "Current Value",
      currentValueShort: "Current",
      targetValue: "Target Value",
      targetValueShort: "Target",
      status: "Status",
      manage: "Manage",
      dataFile: "Data File",
    },
    goodPractice: {
      header: "Good Practices",
      goodPractice: "Good Practice",
      create: "Create a good practice",
      search: "Search",
      id: "ID",
      title: "Title",
      owner: "Owner",
      description: "Description",
      sumOfInitiatives: "Initiatives",
      region: "Oblast",
    },
    search: {
      events: "Events",
      participants: "Participants",
      search: "Search",
      eventFilter: "Event Filter",
      participantFilter: "PARTICIPANTS FILTER",
      dateRange: "Date Range",
      from: "From",
      to: "To",
      viewAll: "View All",
      collapse: "View Less",
      eventsFound: "events",
      participantsFound: "unique participants found",
      distinctCellPhone: "distinct cell phones and",
      distinctNames: "distinct names without a phone number",
      participations: "participants",
      clearAllFilters: "Clear all filters",
      showFilters: "Show Filters",
      owners: "Owners",
      regions: "Regions",
      regionParticipant: "Region (participant)",
      communities: "Municipalities",
      formats: "Event Formats",
      targetGroups: "Target Groups",
      targetLevels: "Target Levels",
      categories: "Event Theme",
      categories_p2: "Event Theme",
      categoryResults: "Results",
      organizers: "Organizers",
      fundingSources: "Funding Sources/Service Request",
      eventCharacters: "Important Events",
      trainers: "Speakers",
      europeActions: "Regional initiatives",
      thematicModules: "Thematic Modules",
      trainingPackageTypes: "Training Packages",
      employerType: "Employer Type",
      employerLevel: "Employer Level",
      gender: "Gender",
      relevance: "Relevance",
      startAsc: "Start date ascending",
      startDesc: "Start date descending",
      endAsc: "End date ascending",
      endDesc: "End date descending",
      exportStats: "Export stats",
      selectAll: "Select all",
      participantsData: "Participants data",
      kind: "Event form",
    },
    preregistration: {
      title:
        "Welcome to pre-registration form of the U-LEAD with Europe event!",
      subtitle:
        "Have you ever participated in U-LEAD events before? Please select one of the options:",
      existingBtn: "I've participated in U&#8209;LEAD events before",
      newBtn: "I've never participated in U&#8209;LEAD events before",
      personalData:
        "I hereby give my consent to the collection of personal data for monitoring and reporting purposes of the U-LEAD with Europe Programme and its regional offices.",
      personalDataDescription:
        "Personal data provided by the participants shall remain strictly confidential. It will be securely stored in the U-LEAD with Europe Programme’s database with restricted access. Persons authorised to access the data will not disclose them in oral or written form. Personal data of the participants will not be transferred to third parties. The information collected will be provided only in an aggregated and non-personalised format. Consent also confirms permission for the use of photo- and video-recording during the event. Participants may withdraw their consent at any time by sending the respective email to monitoring.u-lead@giz.de",
      existingSubtitle:
        "Please, enter your name and surname, and contact phone number for registration.",
      newSubtitle1: "Please fill your data to pre-register to the event ",
      newSubtitle2: "All fields are mandatory.",
      submit: "Pre-registration",
      registrationSuccessful1:
        "Thank you, your record was pre-registered  to the event ",
      registrationSuccessful2:
        "You will be contacted soon by U-LEAD representatives.",
      name: "Last Name, First Name",
    },
    common: {
      view: "View",
      edit: "Edit",
      delete: "Delete",
      cancel: "Cancel",
      print: "Print",
      save: "Save",
      name: "Name",
      gender: "Gender",
      email: "Email",
      cellPhone: "Cell Phone",
      position: "Position",
      employerName: "Employer Name",
      employerLocation: "Employer Location",
      employerAddress: "Employer Address",
      contactAddress: "Contact Adress",
      eventsCount: "Total Events",
      oblastEn: "Oblast",
      employerTypeEn: "Employer Type",
      employerType: "Employer Type",
      employerLevelEn: "Employer Level",
      employerLevel: "Employer Level",
      yes: "Yes",
      no: "No",
      description: "Description",
      memberId: "Members",
      language: "Language",
      langKey: "Language Key",
      ua: "UA",
      en: "EN",
      active: "Active",
      refYear: "Ref Year",
      descEn: "Desc En",
      descUa: "Desc Ua",
      result: "Result",
      keywords: "Keywords",
      createdAt: "Created At",
      updatedAt: "Updated At",
      nameUa: "Name Ua",
      nameEn: "Name En",
      cluster: "Cluster",
      clusterNameEn: "Cluster Name En",
      clusterNameUa: "Cluster Name Ua",
      deleteConfirm: "Are you sure you want to delete ",
      fromEvent: "from Event",
      add: "Add",
      search: "Search",
      file: "File",
      close: "Close",
      MALE: "Male",
      FEMALE: "Female",
      OTHER: "Other",
      "n/a": "Other"
    },
  },
  Ukr: {
    header: {
      home: "Головна",
      registries: "Реєстри",
      indicators: "Індикатори",
      dashboard: "Дешборд",
      searchAnalyse: "Пошук",
      templates: "Шаблони",
      administration: "Адміністрування",
      events: "Заходи",
      participants: "Учасники",
      trainers: "Тренери",
      facilitators: "Модератори",
      userManagement: "Управління користувачами",
      mailLog: "Mail Log",
      team: "Групи",
      fundingSource: "Сервіс ріквест",
      employerLevel: "Рівні організаційя",
      employerType: "Типи організацій",
      oblast: "Області",
      category: "Категорії",
      targetGroup: "Цільові групи",
      targetLevel: "Рівні цільової групи",
      organizer: "Організатори",
      eventFormat: "Формати",
      eventCharacter: "Важливий захід",
      communities: "Громади",
      regionalInitiatives: "Регіональні ініціативи",
      practice: "Належні практики",
      support: "Підтримка",
      supportFeedback: "Feedback",
      contactForm: "Контактна форма",
      forum: "Форум",
      thematicSupportPackages: "Тематичні пакети підтримки",
      programmeIndicators: "Iндикатори програми",
      goodPractice: "Належнi практики",
      csoGrants: "CSO Grants",
      donorProjects: "Donor Funded Projects",
      graphs: "Графи",
      map: "Інтерактивний інструмент для картографування",
    },
    trainers: {
      header:  Settings.Tenant.IsUlead ? "Тренери" : "Speakers",
      trainers:  Settings.Tenant.IsUlead ? "Тренери" : "Speakers",
      trainer: "Тренери",
      personalDetails: "Personal details",
      create: "Створити нового модератора",
      search: "Знайти модератора",
      edit: "Редагувати модератора",
      id: "ID",
      name: "Ім'я",
      nameTranscription: "Транслітерація імені",
      gender: "Стать",
      email: "Email",
      cellPhone: "Мобільний телефон",
      info: "Інформація",
      surveyRating: "Рейтинг",
    },
    home: {
      registries: "РЕЄСТРИ",
      events: "Заходи",
      eventsText: "Переглянути та додати нові заходи U-LEAD",
      participants: "Учасники",
      participantsText: "Переглянути та додати нових учасників заходів U-LEAD",
      trainers: "Тренери та експерти",
      trainersText: "Переглянути та додати тренерів і експертів",
      facilitators: "Модератори",
      facilitatorsText: "Переглянути і додати модераторів",
      hromadas: "Громади",
      hromadasText: "Переглянути останню інформацію про громаду",
      practice: "Належні практики",
      practiceText: "Переглянути, додати та відредагувати належні практики",
      indicators: "Індикатори",
      program: "Індикатори програми",
      programText: "Переглянути або виміряти поточний стан індикаторів U-LEAD",
      dashboard: "Дешборд",
      maps: "Візуалізація даних",
      mapsText: "Переглянути та завантажити візуалізацію даних U-LEAD",
      graphs: "Графи",
      graphsText: "Subtitle",
      search: "Пошук",
      oes: "Пошук заходів та учасників",
      oesText: "Здійснити пошук заходів U-LEAD",
      xs: "Пошук учасників",
      xsText: "Здійснити пошук по учасниках заходів",
      specialInitiatives: "Спеціальні ініціативи",
    },
    events: {
      events: "ЗАХОДИ",
      event: "Захід",
      create: "Створити новий захід",
      search: "Знайти захід",
      id: "ID",
      name: "Назва заходу",
      startDate: "Дата початку",
      endDate: "Дата закінчення",
      oblast: "Область",
      edit: "РЕДАГУВАТИ ЗАХІД ",
      nameEn: "Назва заходу aнгл.",
      eventAddress: "Адреса заходу",
      placeOfEventCoordinates: "Місце проведення заходу",
      nameUa: "Назва заходу укр.",
      region: "Регіон",
      eventFormat: "Формат",
      organizers: "Організатори",
      mediaReferences: "Посилання на ЗМІ",
      internalFeedback: "Внутрішні коментарі/Пріорітети МінРегіону",
      descEn: "Опис англ.",
      targetGroups: "Цільові групи",
      targetLevels: "Рівні цільової групи",
      trainers: "Тренер",
      categories: "Категорії",
      categories_p2: "Тематика заходу",
      results: "Результати",
      descUa: "Опис укр.",
      fundingSources: "Джерело фінансування/Сервіс ріквест",
      eventReport: "Звіт про захід",
      eventFoto: "Фото заходу",
      addParticipant: "Додати учасника",
      downloadParticipantsTemplate: "Завантажити шаблон",
      importParticipants: "Iмпорт учасників",
      printList: "Друкувати список",
      printEmptyList: "Друкувати порожній список",
      printEventInfo: "Друкувати інформацію про захід",
      expectedParticipants: "Очікувана кількість учасників",
      actualParticipants: "Фактична кількість учасників",
      createdBy: Settings.Tenant.IsUlead ?  "Cтворений" : "Посада відповідальної особи",
      owner: Settings.Tenant.IsUlead ? "Власник" : "Посада відповідальної особи",
      goToSurvey: "Перейти до опитування",
      getEvaluationForm: "Завантажити анкету",
      viewStatistics: "Переглянути статистику",
      createSurvey: "Створити опитування",
      employerTypeDistribution: "Тип організацій",
      location: "Місцезнаходження",
      participants: "Учасники",
      cellPhone: "Мобільний телефон",
      gender: "Стать",
      employerLevel: "Рівень організації",
      employerType: "Тип організації",
      regionalInitiatives: "Регіональні ініціативи",
      uniqueHromadas: "КІЛЬКІСТЬ ТГ, ПРЕДСТАВЛЕНИХ НА ЗАХОДІ",
      employerLevelDistribution: "Рівень організацій",
      genderDistribution: "Стать учасників",
      participantRegistered: "ЗАРЕЄСТРОВАНО УЧАСНИКІВ",
      eventCharacters: "Важливий захід",

      thematicPackagesAndNetworkTitle:
        "Чи впроваджується цей захід в рамках ТПП або мереж обміну?",
      thematicPackagesTitle:
          "Чи впроваджується цей захід в рамках тематичних комплексних програм U-LEAD?",

      europeActoinsTitle:
        "Чи впроваджується цей захід в рамках Проектів U-LEAD?",
      trainingPackageTypes: "Навчальні пакети",
      thematicModules: "Тематичні модулі",
      clusterA: "Кластер A: Становлення ефективної та спроможної ОТГ",
      clusterB:
        "Кластер B: Розробка та надання високоякісних муніципальних послуг",
      clusterC: "Кластер C: Сприяння місцевому та регіональному розвитку",
      facilitators: "Модератори",
      actualNumberOfParticipants: "Загальна кількість учасників",
      deleteParticipant: "Видалити з заходу",
      thematicSupportPackage: "Thematic Support Package",
      responsiblePerson: Settings.Tenant.IsUlead ? "Відповідальна особа" : "Ім’я відповідальної особи",
      reportMissing: "Звіт відсутній",
      turnOnPreregistration: "Preregistration turned off",
      turnOffPreregistration: "Preregistration turned on",
      copyToClipboard: "Copy link",
      printPreregList: "Download Preregistered Participants",
      preregisteredParticipants: "Preregistered Participants",
      kind: "Форма заходу",
      hybrid: "Змішана",
      online: "Онлайн",
      live: "Наживо",
      alternative: "Альтернативна (телефон, ел. пошта тощо)",
      experts: "Experts",
      numberWomenParticipated: "Кількість жінок, які взяли участь",
      crossCuttingTopics: "Cross-cutting topics",
    },
    participants: {
      header: "учасники",
      participants: "УЧАСНИКИ",
      create: "Створити учасника",
      search: "Знайти учасника",
      edit: "Редагувати учасника",
      nameTranscription: "Транслітерація імені",
      participant: "Учасник",
      name: "Ім'я",
      gender: "Стать",
      email: "Email",
      cellPhone: "Мобільний телефон",
      personalDetails: "Особиста інформація",
      events: "Заходи",
      employer: "Організація",
      employment: "Місце роботи",
      position: "Посада",
      employerName: "Назва організації",
      employerLocation: "Місцезнаходження організації",
      employerCoordinates: "Координати Організації",
      employerAddress: "Адреса організації (мiсто та область)",
      employerType: "Тип організації",
      employerTypeShort: "Організації",
      employerLevel: "Рівень",
      employerLevelShort: "Рівень",
      community: "Громада",
      region: "Область",
      placeOfEventCoordinates: "Координати Організації",
      contactAddress: "Адреса",
      matchFoundTitle: "Participant Existed",
      noMatchFoundTitle: "Не знайдено учасника",
      noMatchFoundContent: "Не знайдено учасника з такими параметрами в системі",
      checked: "Перевірка iснуючого учасника",
      oblast: "Регіон",
      eventsCount: "Заходи",
    },
    experts: {
      header: "Експерти",
      trainers: "Експерти",
      trainer: "Експерт",
      create: "Створити експерта",
      search: "Пошук експертів",
      edit: "Редагувати експерта",
      id: "Ідентифікатор",
      name: "Імʼя",
      surname: "Прізвище",
      information: "Інформація",
      gender: "Стать",
      email: "Електронна пошта",
      cellPhone: "Номер телефону",
      personalDetails: "Особисті дані",
      expertise: "Сфера експертизи",
      country: "Країна",
      position: "Посада"
    },
    special_initiatives: {
      header: "Спеціальні Ініціативи",
      create: "Створити Спеціальну Ініціативу",
      search: "Пошук Спеціальних Ініціатив",
      edit: "Редагувати Спеціальну Ініціативу",
      id: "Ідентифікатор",
      nameEng: "Назва (англійською)",
      nameUa: "Назва (українською)",
      descriptionEng: "Опис (англійською)",
      descriptionUa: "Опис (українською)",
      startDate: "Дата початку",
      endDate: "Дата завершення",
      partners: "Партнери",
      partnersName: "Назва Партнерів",
      owner: "Власник",
      attachments: "Вкладення",
      addPhoto: "Додати фото",
      addReport: "Додати звіт",
      format: "Формат",
    },
    facilitators: {
      header: "МОДЕРАТОРИ",
      facilitators: "МОДЕРАТОРИ",
      facilitator: "МОДЕРАТОР",
      create: "Створити нового модератора",
      search: "Знайти модератора",
      edit: "Редагувати модератора",
      id: "ID",
      name: "Ім'я",
      nameTranscription: "Транслітерація імені",
      gender: "Стать",
      email: "Email",
      cellPhone: "Мобільний телефон",
      info: "Інформація",
      surveyRating: "Рейтинг",
    },
    templates: {
      templates: "ШАБЛОНИ",
      createNew: "Створити новий шаблон",
      edit: "Редагувати шаблон",
      create: "Створити шаблон",
      title: "Заголовок",
      subTitle: "Підзаголовок",
      file: "Фаил",
    },
    categories: {
      categories: "Категорiï",
      header: "КАТЕГОРІЇ",
      category: "КАТЕГОРІЯ",
      create: "Створити нову категорію",
      search: "Знайти категорію",
      edit: "РЕДАГУВАТИ КАТЕГОРІЮ",
      langKey: "Мова",
      ua: "Укр",
      en: "Англ",
      active: "Активно",
      refYear: "Рік",
      descEn: "Опис Англ",
      descUa: "Опис Укр",
      result: "Результат",
      keywords: "Ключові слова",
    },
    categories_p2: {
      header: "Тематика заходу",
      categories: "Тематика заходу",
      category: "ТЕМАТИКА ЗАХОДУ",
      create: "Створити нову тематику заходу",
      search: "Знайти тематику заходу",
      edit: "РЕДАГУВАТИ ТЕМАТИКУ ЗАХОДУ",
      langKey: "Мова",
      ua: "Укр",
      en: "Англ",
      active: "Активно",
      refYear: "Рік",
      descEn: "Опис Англ",
      descUa: "Опис Укр",
      result: "Результат",
      keywords: "Ключові слова",
    },
    communities: {
      header: "Громади",
      communities: "Громади",
      community: "Громада",
      search: "Знайти Громаду",
      generalInformation: "ЗАГАЛЬНА ІНФОРМАЦІЯ",
      ahCentre: "Центр",
      centreEn: "Центр Англ",
      centreUa: "Центр Укр",
      nameUa: "Ім'я Укр",
      nameEn: "Ім'я Англ",
      id: "ID",
      title: "Назва",
      center: "Центр",
      councilSize: "Pади, що об'єдналися",
      created: "Дата перших виборів",
      establishment: "Дата створення",
      koatuu: "Код",
      admin3pCode: "Admin3PCode",
      region: "Область",
      initiatives: "Ініціативи",
      support: "ТПП",
      participants: "Participants",
      population: "Населення",
      showOnMap: "Показати на карті",
      square: "Площа",
      updatedAt: "Оновлено на",
      date: "Дата",
      eventTitle: "Назва заходу",
      eventFormat: "Формат",
      regionalInitiative: "Регіональні ініціативи",
      participantName: "Ім'я",
      gender: "Стать",
      cellPhone: "Мобільний телефон",
      position: "Посада",
      employerType: "Тип Роботодавця",
      employerName: "Назва організації",
      supportHromada: "ПІДТРИМКА ГРОМАДИ",
      uleadIndicators: "ІНДИКАТОРИ U-LEAD",
      so1dInfo: "Адаптовані внутрішні процедури",
      r2aInfo: "Встановлені місцеві податки/збори",
      uleadStats: "СТАТИСТИКА U-LEAD",
      totalParticipantsCount: "Kількість учасників заходів",
      tspEventsCount: "Заходи ТПП",
      regionalEventsCount: "Заходи Регіональних ініціатив",
      totalEventsNumber: "Загальна кількість подій",
      genderBalance: "Гендерний баланс",
      formatEventsCount: "Формат подій",
      employerTypeCount: "Тип роботодавця",
      otherDonorSupport: "Інші донорські проекти",
    },
    employerLevel: {
      header: "РІВНІ ОРГАНІЗАЦІЙ",
      employerLevels: "РІВНІ ОРГАНІЗАЦІЙ",
      employerLevel: "РІВЕНЬ ОРГАНІЗАЦІЙ",
      create: "Створити новий рівень організацій",
      search: "Знайти рівень організацій",
      edit: "РЕДАГУВАТИ РІВЕНЬ ОРГАНІЗАЦІЙ",
    },
    employerType: {
      header: "Типи організацій",
      employerTypes: "Типи організацій",
      employerType: "TИП ОРГАНІЗАЦІЙ",
      create: "Створити новий тип організацій",
      search: "Знайти тип організацій",
      edit: "РЕДАГУВАТИ ТИП ОРГАНІЗАЦІЙ",
    },
    europeActions: {
      europeActions: "Регіональні ініціативи",
      europeAction: "ДІЯ",
      create: "Створити нову дію",
      search: "Знайти дію",
      edit: "РЕДАГУВАТИ ДІЮ",
    },
    eventCharacter: {
      header: "EVENT CHARACTERS",
      eventCharacters: "EVENT CHARACTERS",
      eventCharacter: "EVENT CHARACTER",
      create: "Створити нову Event Character",
      search: "Знайти Event Character",
      edit: "РЕДАГУВАТИ EVENT CHARACTER",
    },
    format: {
      header: "ФОРМАТИ",
      formats: "ФОРМАТИ",
      format: "ФОРМАТ",
      create: "Створити новий формат",
      search: "Знайти формат",
      edit: "РЕДАГУВАТИ ФОРМАТ",
    },
    fundingSources: {
      header: "ДЖЕРЕЛО ФІНАНСУВАННЯ/СЕРВІС РІКВЕСТ",
      fundingSources: "ДЖЕРЕЛО ФІНАНСУВАННЯ/СЕРВІС РІКВЕСТ",
      fundingSource: "ДЖЕРЕЛО ФІНАНСУВАННЯ/СЕРВІС РІКВЕСТ",
      create: "Створити нове джерело фінансування/сервіс ріквест",
      search: "Знайти джерело фінансування / сервіс ріквест",
      edit: "РЕДАГУВАТИ ДЖЕРЕЛО ФІНАНСУВАННЯ/СЕРВІС РІКВЕСТ",
    },
    organizer: {
      organizers: "Організатори",
      organizer: "Організатор",
      create: "Створити нового Організатора",
      search: "Знайти Організатора",
      edit: "РЕДАГУВАТИ ОРГАНІЗАТОРА",
    },
    region: {
      regions: "ОБЛАСТІ",
      region: "ОБЛАСТЬ",
      create: "Створити нову область",
      search: "Знайти область",
      edit: "РЕДАГУВАТИ ОБЛАСТЬ",
    },
    targetGroup: {
      header: "ЦІЛЬОВІ ГРУПИ",
      targetGroups: "ЦІЛЬОВІ ГРУПИ",
      targetGroup: "ЦІЛЬОВІ ГРУПА",
      create: "Створити нову цільову групу",
      search: "Знайти цільову групу",
      edit: "РЕДАГУВАТИ ЦІЛЬОВУ ГРУПУ",
    },
    targetLevel: {
      header: "РІВНІ ЦІЛЬОВОЇ ГРУПИ",
      targetLevels: "РІВНІ ЦІЛЬОВОЇ ГРУПИ",
      targetLevel: "РІВЕНЬ ЦІЛЬОВОЇ ГРУПИ",
      create: "Створити новий рівень цільової групи",
      search: "Search for Target Level",
      edit: "РЕДАГУВАТИ РІВЕНЬ ЦІЛЬОВОЇ ГРУПИ",
    },
    team: {
      teams: "ГРУПИ",
      team: "ГРУПА",
      create: "Створити нову групу",
      search: "Знайти групу",
      edit: "РЕДАГУВАТИ ГРУПУ",
    },
    thematicPackages: {
      thematicPackages: "ТЕМАТИЧНІ ПАКЕТИ ПІДТРИМКИ U-LEAD",
      thematicPackage: "ТЕМАТИЧНІЙ ПАКЕТ ПІДТРИМКИ",
      create: "Створити новий пакет",
      search: "Знайти пакет",
      edit: "РЕДАГУВАТИ ПАКЕТ",
    },
    csoGrants: {
      csoGrants: "Проекти ГО",
      csoGrant: "Проект ГО",
      create: "Створити новий проект ГО",
      search: "Знайти проект ГО",
      edit: "РЕДАГУВАТИ ПРОЕКТ ГО",
    },
    csoGrant: {
      search: "Search",
      id: "ID",
      year: "Рiк",
      projectTitle: "Назва",
      projectTitleEn: "",
      cso: "CSO",
      csoEn: "",
    },
    donorProjects: {
      donorProjects: "Donor Funded Projects",
      donorProject: "Donor Funded Project",
      create: "Створити новий Donor Funded Project",
      search: "Знайти Donor Funded Project",
      edit: "РЕДАГУВАТИ DONOR FUNDED PROJECT",
    },
    donorProject: {
      search: "Search",
      id: "ID",
      title: "Project Title",
      donor: "Donor",
    },
    indicators: {
      create: "Create an Indicator",
      code: "Code",
      indicatorText: "Indicator Text",
      frequency: "Frequency",
      updated: "Updated",
      currentValue: "Current Value",
      currentValueShort: "Current",
      targetValue: "Target Value",
      targetValueShort: "Target",
      status: "Status",
      manage: "Manage",
      dataFile: "Data File",
    },
    goodPractice: {
      header: "Належні практики",
      goodPractice: "Належні практики",
      create: "Створити нову належну практику",
      search: "Знайти",
      id: "ID",
      title: "Назва",
      owner: "Власник",
      description: "Опис",
      sumOfInitiatives: "Initiatives",
      region: "Область",
    },
    user: {
      users: "КОРИСТУВАЧІ",
      user: "КОРИСТУВАЧ",
      create: "Створити нового користувача",
      search: "Знайти користувача",
      edit: "РЕДАГУВАТИ КОРИСТУВАЧА",
      id: "ID",
      login: "Логін",
      email: "Електронна пошта",
      activated: "Активований",
      lang_key: "Мова",
      authorities: "Профілі",
      created_date: "Дата створення",
      last_modified_by: "Змінено",
      last_modified_date: "Дата зміни",
      name: "Ім'я",
      password: "Пароль",
      passwordConfirm: "Підтвердити пароль",
      createdBy: "Cтворенний",
      profiles: "Профілі",
    },
    search: {
      events: "Заходи",
      participants: "Учасники",
      search: "Пошук",
      eventFilter: "Фільтр подій",
      participantFilter: "Фільтр учасників",
      dateRange: "Проміжок часу",
      from: "Від",
      to: "До",
      viewAll: "Подивитись все",
      collapse: "Згорнути",
      eventsFound: "знайдені події",
      participantsFound: "знайдено різних учасників",
      distinctCellPhone: "різних мобільних телефонів і",
      distinctNames: "різні імена",
      participations: "учасники",
      clearAllFilters: "Очистити всі фільтри",
      showFilters: "Show Filters",
      owners: "Власники",
      regions: "Регіони",
      regionParticipant: "Регіон (учасник)",
      communities: "Громади",
      formats: "Формати подій",
      targetGroups: "Цільові групи",
      targetLevels: "Рівні цільової групи",
      categories: "Тематика заходу",
      categories_p2: "Тематика заходу",
      categoryResults: "Результати",
      organizers: "Організатори",
      fundingSources: "Джерело фінансування/Сервіс ріквест",
      eventCharacters: "Важливий захід",
      trainers: "Тренери",
      europeActions: "Регіональні ініціативи",
      thematicModules: "Тематичнi модулі",
      trainingPackageTypes: "Навчальні Пакети",
      employerType: "Тип роботодавця",
      employerLevel: "Рівень роботодавця",
      gender: "Стать",
      relevance: "Релевантність",
      startAsc: "Дата початку: зростання",
      startDesc: "Дата початку: спадання",
      endAsc: "Дата закінчення: зростання",
      endDesc: "Дата закінчення: спадання",
      exportStats: "Експорт статистики",
      selectAll: "Вибрати все",
      participantsData: "Дані учасників",
      kind: "Форма заходу",
    },
    preregistration: {
      title:
        "Вітаємо у формі попередньої реєстрації на захід Програми «U-LEAD з Європою»!",
      subtitle:
        "Чи брали ви коли-небудь участь у заходах Програми U-LEAD? Будь ласка, оберіть один із варіантів:",
      existingBtn: "Я брав/ла участь в заходах U&#8209;LEAD раніше",
      newBtn: "Я ще не брав/ла участі у заходах Програми U&#8209;LEAD",
      personalData:
        "Я даю згоду на обробку своїх персональних даних з метою моніторингу та підготовки звітності Програми «U-LEAD з Європою» та її регіональних офісів.",
      personalDataDescription:
        "Персональні дані, надані учасниками, є суворо конфіденційною інформацією. Вони надійно зберігатимуться у базі даних «U-LEAD з Європою» з обмеженим доступом. Авторизовані особи, які оперуватимуть даними, не розголошуватимуть їх в усній або письмовій формі. Жодні персональні дані не передаватимуться третім особам. Зібрана інформація надаватиметься лише в узагальненому та неперсоналізованому форматі. Заповнення цієї форми також означає згоду особи на проведення фото- та відеозйомки під час заходу. Учасники можуть в будь-який час відкликати свою згоду на обробку персональних даних надіславши відповідний лист на електронну пошту  monitoring.u-lead@giz.de",
      existingSubtitle:
        "Будь ласка,  вкажіть ПІБ та номер телефону для реєстрації.",
      newSubtitle1:
        "Будь ласка, заповніть поля нижче, щоб попередньо зареєструватися на захід ",
      newSubtitle2: "Всі поля обов’язкові для заповнення.",
      submit: "Попередня реєстрація",
      registrationSuccessful1:
        "Дякуємо, ви були попередньо зареєстровані на захід ",
      registrationSuccessful2:
        "Скоро з вами cконтактують представники Програми U-LEAD.",
      name: "Прізвище, Ім’я",
    },
    common: {
      view: "Переглянути",
      edit: "Змінити",
      delete: "Видалити",
      cancel: "Скасувати",
      print: "Друкувати",
      save: "Зберегти",
      name: "Ім'я",
      gender: "Стать",
      email: "Email",
      cellPhone: "Мобільний телефон",
      position: "Посада",
      employerName: "Назва організації",
      employerAddress: "Адреса організації",
      employerLocation: "Місцезнаходження організації",
      contactAddress: "Адреса організації",
      eventsCount: "Доступно/усього заходів",
      oblastEn: "Регіон",
      employerTypeEn: "Тип організації",
      employerLevelEn: "Рівень організації",
      employerType: "Тип організації",
      employerLevel: "Рівень організації",
      yes: "Так",
      no: "Ні",
      description: "Опис",
      memberId: "Члени",
      language: "Мова",
      langKey: "Мова",
      ua: "Укр",
      en: "Англ",
      active: "Активно",
      refYear: "Рік",
      descEn: "Опис Англ",
      descUa: "Опис Укр",
      result: "Результат",
      keywords: "Ключові слова",
      createdAt: "Створено в",
      updatedAt: "Оновлено на",
      nameUa: "Ім'я Укр",
      nameEn: "Ім'я Англ",
      cluster: "Kластер",
      clusterNameEn: "Кластер Ім'я Англ",
      clusterNameUa: "Кластер Ім'я Укр",
      deleteConfirm: "Ви впевнені, що бажаєте видалити ",
      fromEvent: "із Заходу",
      add: "Додати",
      search: "Пошук",
      file: "Фаил",
      close: "Закрити",
      MALE: "Чол.",
      FEMALE: "Жін.",
      OTHER: "Other",
      "n/a": "Other"
    },
  },
};
